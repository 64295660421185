<template>
  <dashboard>
    <el-row id="employer" :gutter="15">
      <el-col :xs="24">
        <div class="back">
          <a href="#" class="mb-3 el-button el-button--info" @click.prevent="$router.go(-1)">
            <i class="mr-2 el-icon-back" />Back
          </a>
          <!-- <router-link :to="`/employer/jobs/${application.job_id + 10000}`" ></router-link> -->
        </div>
        <div class="jobview application">
          <el-card>
            <div class="pt-0 header">
              <el-row class="visible-xs" style="position: relative;">
                <el-col class="text-center">
                  <img :src="profilePhotoUrl(freelancer)" class="mt-2 mb-2" style="height: 120px; width: 120px;">
                  <h4 class="mb-0" :class="{ blur: freelancer.blurred, 'orange-text': ! freelancer.blurred }">{{ freelancer.first_name }} {{ freelancer.last_name }}</h4>
                  <h6 v-if="freelancer.profile" class="mb-0">{{ freelancer.profile.title }}</h6>
                </el-col>
              </el-row>
              <el-row :gutter="20" type="flex" class="hidden-xs">
                <el-col :xs="24" :sm="4" :md="3" class="align-self-center">
                  <img :src="profilePhotoUrl(freelancer)" class="w-100">
                </el-col>
                <el-col :xs="18" :sm="17" :md="19" class="align-self-center hidden-xs">
                  <h4 class="mb-0" :class="{ blur: freelancer.blurred, 'orange-text': ! freelancer.blurred }">{{ freelancer.first_name }} {{ freelancer.last_name }}</h4>
                  <h6 v-if="freelancer.profile" class="mb-2">{{ freelancer.profile.title }}</h6>
                </el-col>
              </el-row>
            </div>
            <div class="mx-0 text-center apply">
              <router-link :to="`/employer/profile/${application.member.freelancer_id}`">
                <el-button type="warning" class="mr-2">View Profile</el-button>
              </router-link>
              <message-button :freelancer="Freelancer" />
              <mark-as-hired-button :freelancer="Freelancer" />
            </div>
          </el-card>
          <el-card class="mt-3">
            <div class="">
              <h5 class="mb-3 orange-text bold">Application</h5>
              <hr class="mb-3">
              <div class="intro">
                <h6>
                  <span class="bold">Subject: </span>
                  <span v-text="application.subject" />
                </h6>
              </div>
              <div class="description">
                <h6><span class="bold">Message: </span></h6>
                <p v-text="application.message" />
                <br>
                <h6 v-if="application.attachment_path">
                  <span class="bold">File: </span>
                  <a href="javascript:void(0)" @click="getAttachmentFile({ path:'/files/'+getAttachment.label+getAttachment.extension, id: application.attachment.id })" target="_blank" class="orange-text">{{ getAttachment.label + getAttachment.extension }}</a>
                </h6>
              </div>
            </div>
          </el-card>
        </div>
      </el-col>
    </el-row>
  </dashboard>
</template>

<script>
import Dashboard from '~/components/Dashboard/Dashboard'
import Application from '~/models/Jobs/Application'
import showsProfilePhoto from '~/mixins/showsProfilePhoto'
import url from '~/helpers/url'
import MarkAsHiredButton from '~/components/Freelancer/Buttons/MarkAsHired'
import Freelancer from '~/models/Freelancer'
import MessageButton from '~/components/Freelancer/Buttons/Message'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: { Dashboard, MarkAsHiredButton, MessageButton },

  mixins: [showsProfilePhoto],

  data () {
    return {
      application: {
        member: {},
      },
    }
  },

  computed: {
    ...mapGetters(['getAttachment']),
    freelancer () {
      return this.application.member
    },

    Freelancer () {
      return new Freelancer(
        this.freelancer.freelancer_id,
        this.application.member,
        false,
      )
    },

    attachmentLink () {
      const application = this.application

      // if attachment not given, give a hash link
      if (! application.attachment_path) {
        return '#'
      }

      return url(application.attachment_path)
    },
  },

  watch: {
    application (v) {
      if (v.attachment && v.attachment.id) {
        Promise.all([this.getAttachmentsData(v.attachment.id)])
      }
    }
  },

  created () {
    Application.find(this.$route.params.application_id)
      .then(response => {
        this.application = response.data.data
      })
  },

  methods: {
    ...mapActions(['getAttachmentFile', 'getAttachmentsData']),
  }
}
</script>

<style scoped>
#employer .jobview .header {
  padding: 20px 0;
}
.mobile-profile{
  position: absolute;
  top: 0px;
  right: 0px;
}
.mobile-profile:focus{
  outline: none;
}
.description a{
  text-decoration: underline;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.visible-xs {
  position: relative;
}
h6 {
  line-height: 1.6;
}
.description {
  font-size: .9rem;
}
@media (max-width: 767px){
  .jobview .apply {
    border: 0;
    margin: 0;
  }
  .jobview>>>.el-row--flex {
    /*display: inline-block;*/
  }
  .jobview>>>.el-card__body{
    padding: 15px;
  }
  .jobview .header {
    padding-bottom: 0;
  }
  .jobview .header img {
    display: block;
    margin: 10px auto;
    height: 80px !important;
    width:  80px !important;
  }
  .apply{
    padding-bottom: 0;
  }
  .apply button{
    margin-right: 10px;
    padding: 7px 15px;
    font-size: 12px;
    border-radius: 3px;
    width: inherit;
  }
  /*.apply button:first-child{
    margin-bottom: 10px;
  }
  .apply button:last-child{
    margin-top: 10px;
  }*/
  .jobview .details .intro p, .jobview .details .description p {
    text-align: left;
    line-height: 1.6;
  }
}

</style>
